<template>
  <div class="secure-item">
    <!-- 已经关联 -->
    <template v-if="data.email">
      <div class="detail-item">
        <span>邮箱</span>
        <span>{{$t('userSet.ac_bindEmail')}}：{{data.email || '--' | omitAccount}}</span>
        <a-button
          type="primary"
          @click="handleItem($var.Handle.edit)"
        >{{$t('common.change')}}</a-button>
      </div>
    </template>
    <!-- 没有关联 -->
    <template v-else>
      <div class="detail-item">
        <span>邮箱</span>
        <span>{{$t('userSet.ac_noConnectEmailText')}}</span>
          <a-button
            type="primary"
            @click="handleItem($var.Handle.add)"
          >{{$t('common.connect')}}</a-button>
      </div>
    </template>

    <dialog-add
      :type="type"
      :handle="handleType"
      @success="$emit('success')"
      :visible.sync="showAddDialog"
    ></dialog-add>

    <dialog-verify
      :type="type"
      :number="data.email"
      :handle="handleType"
      @change="onVerifyChange"
      @success="$emit('success')"
      :visible.sync="showVerifyDialog"
    ></dialog-verify>
  </div>
</template>

<script>
  import DialogAdd from './DialogAdd.vue';
  import DialogVerify from './DialogVerify.vue';

  export default {
    data() {
      return {
        type: 'email',
        showAddDialog: false,
        showVerifyDialog: false,
        handleType: this.$var.Handle.add,
      }
    },

    props: {
      data: {
        type: Object,
        default() {
          return {};
        }
      }
    },

    components: {
      DialogAdd, DialogVerify
    },

    methods: {
      onVerifyChange() {
        this.showAddDialog = true;
      },

      handleItem(type) {
        this.handleType = type;

        if (type === this.$var.Handle.add) {
          this.showAddDialog = true;
        }
        else if (type === this.$var.Handle.edit) {
          this.showVerifyDialog = true;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .detail-item {
    margin-top:52px;
    width: 100%;
    span{
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
    }
    span:nth-child(1){
      text-align:right;
      width:10%;
      height: 22px;
      color: #666666;
    }
    span:nth-child(2){
      width:40%;
      margin-left: 5%;
      color: #333333;
    }
    button{
      margin-left: 20%;
    }
  }
</style>
