<template>
  <ng-dialog :visible.sync="show" :title="`绑定${typeText}`" mHeight="auto">
    <second-step
      :type="type"
      :handle="this.$var.Handle.add"
      :codeType="codeType"
      ref="secondStep"
    ></second-step>

    <template v-slot:footer>
      <a-button
        type="primary"
        size="large"
        @click="handleOk"
        :loading="confirmLoading"
      >确认</a-button>
    </template>
  </ng-dialog>
</template>

<script>
  import {dialogMixins} from '@/mixins/index';
  import NgDialog from '@/components/NgDialog.vue'
  import SecondStep from './SecondStep.vue'

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,
      }
    },

    props: {
      // phone email
      type: {
        type: String,
        default: 'phone'
      },

      number: String,
      data: {
        type: Object,
        default() {
          return {}
        }
      },
    },

    computed: {
      codeType() {
        return this.type === 'email' ? '2' : '1';
      },

      typeText() {
        return this.type === 'email' ? '邮箱' : '手机号';
      },

      // 绑定手机/邮箱
      bindCodeFunc() {
        return this.type === 'email' ? this.$axios.Account_bindEmail : this.$axios.Account_bindPhone;
      }
    },

    watch: {
      visible(newVal) {
        if (newVal) {
          this.step = '1';
        }
      }
    },

    mixins: [dialogMixins],

    components: {
      NgDialog,
      SecondStep
    },

    methods: {

      handleOk() {
        this.$refs.secondStep.handleOk().then(values => {
          this.confirmLoading = true;

          this.bindCodeFunc(values).then(res => {
            const data = res.data;

            if (data && data.code == 0) {
              this.$message.success(this.handle === this.$var.Handle.add ? this.$t('userSet.ac_tipBindSuccess') : this.$t('userSet.ac_tipChangeSuccess'));
              this.$emit('update:visible', false);
              this.$emit('success');
            }

            this.confirmLoading = false;
          }).catch(() => {
            this.confirmLoading = false;
          })
        });
      },

      handleCancel() {

      },
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ant-modal-header {
      position: initial !important;
      border-bottom: 1px solid #F0F4F5 !important;
    }
    .ant-modal-footer{
      text-align:center;
      border: 0;
      padding-bottom: 37px;
    }
    .ant-modal-close{
      right: 0 !important;
    }
    .ant-drawer-title{
      height: 30px;
      font-size: 22px;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
    }
    .ant-drawer-title-span{
      margin-top: 12px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
    .ant-modal-body{
      padding-top: 45px;
    }
  }
  .progress-wrapper {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 20px;
    color: rgba($color: #000000, $alpha: 0.25);

    .progress-item {
      padding: 10px 0;

      &.active {
        color: $primary-color;
      }
    }

    .step-icon {
      width: 16px;
      height: 16px;
    }
  }

  @media screen and(min-width: $screen-width-md) {
    .progress-wrapper {
      justify-content: space-around;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .progress-wrapper {
      justify-content: space-between;
    }
  }
</style>
