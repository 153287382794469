<template>
  <a-form :form="form" layout="horizontal" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"  class="ng-form" :colon="false">
    <a-form-item class="m-bottom-0" label="验证码">
      <a-form-item style="display: inline-block; width: calc(100% - 130px); margin-right: 10px">
        <a-input
          size="large"
          :placeholder="$t('template.typeSth', {name: $t('form.code')})"
          v-decorator="['vfcode', { rules: [$validate.required], initialValue: '', trigger: 'blur' }]"
        />
      </a-form-item>

      <a-form-item :style="{display: 'inline-block', width: '120px'}">
        <form-send-code-button
          type="sub"
          size="large"
          class="font-base"
          re-text="重新发送"
          :url-func="$axios.SendSelfCode"
          @click="onClickSend"
          @sendSuccess="onSendSuccess"
        ></form-send-code-button>
      </a-form-item>
    </a-form-item>
  </a-form>
</template>

<script>
  import {FormSendCodeButton} from '@/components/index';

  export default {
    data() {
      return {

      }
    },

    props: {
      type: String,
      codeType: String,
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
        name: 'linkman'
      })
    },

    components: {
      FormSendCodeButton
    },

    methods: {
      onClickSend(next) {
        let param = {type: this.codeType};

        next(param);
      },

      onSendSuccess(data) {
        if (data && data.code == 0) {
          // this.showSuccessTip = true;
          this.$message.success('发送成功');
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
